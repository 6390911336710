.cp-counter {
  display: block;
  text-align: right;
  .cp-counter-prefix,
  .cp-counter-number,
  .cp-counter-suffix {
      color: rgba(255,255,255,0.8);
      font-family: Barlow;
      font-size: 12px;
      letter-spacing: 1.2px;
      line-height: 19px;
  }
  .cp-counter-prefix {
    margin-right: 5px;
  }
  .cp-counter-suffix {
    margin-left: 5px;
  }
  .cp-counter-number {
    text-align: center;
    display: inline-block;
    height: 32px;
    width: 20px;
    background-color: #211F6F;
    color: $white;
    margin: 0 5px 0 2px;
    position: relative;
    padding-top: 5px;
  }
}