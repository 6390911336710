//body.home {
//  @include media-breakpoint-down(xxl) {
//    .navbar-collapse {
//      top: 80px;
//    }
//    .navbar-collapse.collapsing,
//    .navbar-collapse.show {
//      position: inherit;
//      top: auto;
//    }
//  }
//}
//
//.cp-icon-01,
//.cp-icon-02,
//.cp-icon-03,
//.cp-icon-04,
//.cp-icon-fund-01,
//.cp-icon-fund-02,
//.cp-icon-fund-03,
//.cp-icon-fund-04,
//.cp-icon-fund-05,
//.cp-icon-fund-06,
//.cp-icon-fund-07 {
//  display: block;
//  width: 65px;
//  height: 65px;
//  background-repeat: no-repeat;
//  background-size: cover;
//}
//

.cp-scroll-down {
  pointer-events: none !important;
  color: #32D2B4;
  width: 100%;
  position: fixed;
  bottom: 0px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.17px;
  line-height: 17px;
  div {
    display: inline-block;
    max-width: 110px;
    border-radius: 30px;
    background: rgba(#1d1359, .8);
    span {
      filter: drop-shadow(0 2px 2px rgba(50, 210, 180, 0.2));
    }
  }
  opacity: 1;
  transition: all ease-out .2s;
  &.hidden {
    bottom: 20px;
    transition: all ease-in .2s;
    opacity: 0;
  }
}
.cp-benefits {
  transition: all ease-in-out .3s;
  max-height: 880px;
  margin-bottom: 50px;
  opacity: 1;
  @include media-breakpoint-up(lg) {
    span, hr {
      transition: all ease-in-out .3s;
    }
    &.hidden {
      max-height: 80px;
      span, hr {
        pointer-events: none !important;
        max-height: 0;
        margin-top: 0;
        transition: all ease-in-out .3s;
        opacity: 0;
      }
    }
  }
}
.cp-benefit-toggle {
  height: 23px;
  cursor: pointer;
  position: relative;
  margin: auto;
  z-index: 5;
  width: 100%;
  color: #32D2B4;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 23px;
  text-align: center;
  span {
    border-bottom: 1px solid #32D2B4;
  }
}

#main-body {
  overflow: hidden !important;
}