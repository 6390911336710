@import "variables";
//
//body {
//  border-top: 10px solid $green;
//}

// Prevent accidental whitespace on right.
html,
body {
  overflow-x: hidden;
}

.nav {
  background: #45D2B4;
  //border-bottom: 1px solid rgb(59,181,169);
  .wordmark {
    left: 102px;
    position: absolute;
    color: #211F6F;
    font-family: Barlow;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 5px;
    line-height: 19px;
  }
  &.navbar {
    z-index: 2000;
    padding-bottom: 0;
  }
}
//  .nav-link {
//    //color: $dark;
//    margin-left: 1em;
//    margin-right: 1em;
//    padding-left: .1em;
//    padding-right: .1em;
//    font-weight: normal;
//    border-bottom: 4px solid transparent;
//
//    &:hover {
//      border-bottom: 4px solid $green;
//    }
//
//    &.active {
//      font-weight: bold;
//    }
//
//    //@include media-breakpoint-down(lg) {
//    //  display: inline-flex;
//    //}
//  }
//
//  .navbar-toggler {
//    width: 1.5em;
//    height: 1.5em;
//    background: $dark;
//    border-radius: 0;
//    margin: 0;
//    padding: 0;
//    margin-top: .7em;
//    .navbar-toggler-icon {
//      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2883, 256, 186, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
//      background-position: center;
//      background-size: 78%;
//    }
//  }
//  &.cp-portfolio-nav {
//    .navbar-toggler {
//      @include media-breakpoint-down(lg) {
//        margin-top: 1.3em;
//      }
//    }
//  }
/**///}
//
//.navbar.navbar-light {
//  background: transparent;
//  transition: all ease-in-out 200ms;
//  .bg-gradient1 {
//    width: 66px;
//    height: 80px;
//    overflow: hidden;
//    display: inline-block;
//    vertical-align: center;
//    margin-bottom: -10px;
//    position: absolute;
//    top:0;
//    left:0;
//  }
//  &.cp-portfolio-nav {
//    background-color: $light;
//    border-bottom: 1px solid $gray-400;
//    margin: 0 !important;
//    min-height: 80px;
//    padding: 0 $grid-gutter-width 0 70px;
//  }
//  @include media-breakpoint-down(lg) {
//    background-color: $light;
//    &.navbar-expanded {
//      background-color: $dark;
//      a.active,
//      a {
//        color: $green;
//        &:hover,
//        &:active {
//          color: $light;
//        }
//      }
//      #horizontal-logo {
//        filter: brightness(100);
//      }
//      #wallet {
//        margin: 10px $grid-gutter-width $grid-gutter-width 27px;
//      }
//    }
//    margin-top: 0 !important;
//    display: block;
//    margin-left: -$grid-gutter-width * 10;
//    margin-right: -$grid-gutter-width * 10;
//    padding-left: $grid-gutter-width * 10;
//    padding-right: $grid-gutter-width * 10;
//  }
//}
//
//#brandmark-logo {
//  top: -21px;
//  position: relative;
//}
//
//#horizontal-logo {
//  max-width: 230px;
//  @include media-breakpoint-up(md) {
//    max-width: 300px;
//  }
//}
//
//.sub-head {
//  font-size: $font-size-base * 1.2;
//  line-height: 1.6em;
//}
//body.portfolio #root {
//  padding-top: 70px;
//}