.cp-investment-usd,
.cp-investment-usd-md,
.cp-investment-usd-lg {
  font-weight: $font-weight-bold;
  font-size: 40px;
  max-width: 99%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cp-investment-usd {
  padding-top: 10px;
  font-size: 28px;
}

.cp-investment-usd-md {
  font-size: 35px;
  letter-spacing: 0.39px;
}

.cp-investment-stats {

  .down,
  .up {
    padding: 3px 8px;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    margin: 0 20px 0 0;
    display: inline-block;
  }

  .down::before,
  .up::before {
    content: "";
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='currentColor' class='bi bi-triangle-fill' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z'/%3E%3C/svg%3E");
    margin-right: 6px;
    top: 1px;
    display: inline-block;
    width: 12px;
    height: 12px;
  }

  .up {
    color: $dark;
    //background: rgb(81, 238, 232);
    background: linear-gradient(45deg, rgb(158, 239, 208) 0%, rgb(158, 239, 208) 100%);
  }

  .down {
    color: $dark;
    //background: rgb(81, 238, 232);
    background: linear-gradient(45deg, rgba(250, 197, 201, 1) 0%, rgba(248, 177, 230, 1) 100%);

    &::before {
      transform: rotate(180deg);
    }
  }
}

.cp-fund-sidebar {
  //background: rgb(44, 42, 118);
  background: rgb(33,31,111);
  padding-left: 43px;
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    z-index: 3030;
    right: 0;
    border-left: solid 2px #45D2B4;
    max-height: 1vh;
    min-height: 718px;
  }
}

.cp-investment-muted {
  font-size: $font-size-sm;
  color: $gray-500;
}

.cp-investment-header-sm {
  display: block;
  color: rgba(255, 255, 255, 0.8);
}

.cp-investment-header-2 {
  color: rgba(255, 255, 255, 0.8);
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  margin: 15px 0 25px 0;
  display: block;
}

.cp-investment-coins {
  margin: 0 20px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 712px;
  padding-right: 10px;

  .row:last-child .cp-investment-coin {
    border-bottom: none !important;
  }
}

.cp-investment-coin {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  height: 82px;
  padding-top: 20px;

  img {
    top: 4px;
    position: relative;
  }

  .currency {
    // margin-left: 12px;
    color: rgba(255,255,255,0.8);
    font-family: Barlow;
    font-size: 18px;
    letter-spacing: 0.2px;
    line-height: 22px;
  }

  .currency-name {
    text-decoration: none;
    //border-bottom: #gray 1px solid;
    display: block;
    color: rgba(255,255,255,0.8);
    font-family: Barlow;
    font-size: 14px;
    letter-spacing: 0.16px;
    line-height: 17px;
  }

  .percentage {
    color: rgba(255,255,255,0.8);
    font-family: Barlow;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.18px;
    line-height: 19px;
    text-align: right;
    position: relative;
    top: -40px;
  }

  .value {
    //position: relative;
    //top: -18px;
    color: rgba(255,255,255,0.8);
    font-family: Barlow;
    font-size: 14px;
    letter-spacing: 0.16px;
    line-height: 17px;
    text-align: right;
  }

  .balance {
    position: relative;
    top: -36px;
    color: $white;
    font-family: Barlow;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 22px;
    text-align: right;
  }
}

.cp-investment-coin {
  .crypto-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    margin-right: 39px;
    transition: all ease-in-out .3s;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(#211F6F,1) 65%, rgba(#211F6F,0) 72%);
    img {
      //background-color: #F7F53D;
      top: -.5px;
      left: 0;
      padding: 0;
      margin: 0;
      width: 36px;
      height: 36.5px;
      border-radius: 50%;
      overflow: hidden;
      //-webkit-filter: grayscale(100%) brightness(1.25) opacity(.8);
      //filter: opacity(0.7);
    }
  }

  //&:hover {
  //  .crypto-icon {
  //    transition: all ease-in-out .1s;
  //    -webkit-filter: none;
  //    filter: none;
  //  }
  //}
}

body.portfolio #root,
.cp-portfolio-body {
  background: #211F6F;
  min-height: 65vh !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #99e7c9 #211f6f;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: #211f6f;
}

*::-webkit-scrollbar-thumb {
  background-color: #99e7c9;
  border-radius: 0px;
  border: 0px none #ffffff;
}

.pot-description {
  color: #32D2B4;
  font-family: Barlow;
  font-size: 16px;
  letter-spacing: 0.18px;
  line-height: 24px;
}

.cp-investment-assets {
  .cp-investment-asset {
    background: #1D1259;
    color: #fff;
    //display: block;
    margin-bottom: 40px;

    .cp-investment-header {
      color: #32D2B4;
      font-family: Barlow;
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 22px;
    }
  }
}

.cp-pot-assets-table {
  color: #32D2B4;
  font-family: Barlow;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 22px;
}

.cp-portfolio-header {
  height: 128px;
  position: relative;
  z-index: 2;
  pointer-events: none;

  .cp-investment-chart-zoom {
    pointer-events: auto;

    button {
      border-radius: 20px;
      width: 110px;
      height: 24px;
      margin: 0;
      padding: 0;
      background-color: #F7F53D !important;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.06px;
      line-height: 22px;

      &:hover,
      &.active {
        background-color: #fffdbc;
      }
    }

    .dropdown-menu {
      width: 110px;
      background-color: #F7F53D;
      border: 0;
      border-radius: 20px;

      .dropdown-item {
        border-radius: 20px;
        width: 110px;
        padding: 0.25rem 1rem;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.06px;
        line-height: 22px;
      }
    }
  }
}

.cp-investment-chart {
  padding-bottom: 12px;

  > canvas {
    //  border: 0;
    //  margin: 0;
    //  padding: 0;
    //  background-color: #45D2B4;
    @extend .swing-in-top-fwd;
    margin: 0 -5px;
    max-height: 418px;
    margin-top: -128px;
    @include media-breakpoint-down(lg) {
      min-height: 300px !important;
    }
  }
}