// Override bootstrap variables as found in:
// node_modules/bootstrap/scss/_variables.scss

$font-family-sans-serif: 'Barlow', 'Noto Sans', sans-serif, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$web-font-path: 'https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap';
$font-family-serif: 'Merriweather', serif;
$font-family-monospace: 'Noto Sans', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

//$line-height-base: 1.5;
//$line-height-sm: 1.25;
//$line-height-lg: 2;

$spacer: 1rem !default;

$white: #fff !default;
$gray-100: #f8f8f8 !default;
$gray-200: #eeeeee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #414141 !default;
$gray-800: #333333 !default;
$gray-900: #1d1d1d !default;
$black: #000 !default;

$blue: #211F6F !default; // CrocPot color
$indigo: #1D1359 !default; // CrocPot color
$purple: #6f42c1 !default;
$pink: #ea39b8 !default;
$red: #e44c55 !default;
$orange: #f1b633 !default;
$yellow: #ffc107 !default;
$green: #32D2B4 !default; // CrocPot color
$teal: #3f81a2 !default;
$cyan: #9EEFD0 !default; // CrocPot color

$primary: $green !default;
$secondary: $blue !default;
$success: darken($green, 30) !default;
$info: $gray-400 !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $white !default;
$dark: $indigo !default;

$body-bg: #170E4D !default;
$body-color: $light !default;

$accordion-bg: $dark !default;
$thumbnail-bg: $dark !default;

$input-placeholder-color: #ced4da;
$input-btn-line-height: 2.5;

$grid-gutter-width: 2.25rem;

$form-check-input-width: 1.25em;
$form-check-input-height: 1.25em;

$btn-font-weight: 800;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1440px // Custom for CF
) !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 2.8;

//$tooltip-font-size: 16px;
$tooltip-max-width: 262px !default;
$tooltip-color: $white !default;
$tooltip-bg: $gray-700 !default;
$tooltip-border-radius: 0 !default;
$tooltip-opacity: 1 !default;
//$tooltip-padding-y:$spacer * .25;
//$tooltip-padding-x:$spacer * .5;

$tooltip-arrow-width: 1.4rem !default;
$tooltip-arrow-height: .8rem !default;
$tooltip-arrow-color: $gray-700 !default;

$modal-inner-padding: $spacer * 2.3 !default;
$modal-content-color: $light !default;
$modal-content-bg: $gray-800 !default;
$modal-content-border-color: transparent !default;
$modal-content-border-width: 0 !default;
$modal-content-border-radius: 0 !default;

$border-radius: 0 !default;
$card-bg: transparentize($dark, .99) !default;
//$border-radius-sm:            .2rem !default;
//$border-radius-lg:            .3rem !default;
//$border-radius-pill:          50rem !default;

//$modal-sm:                          300px !default;
//$modal-md: 555px !default;
$modal-lg: 755px !default;
//$modal-xl:                          1140px !default;
$btn-padding-x-sm: 1.5rem;