#wallet {
  white-space: nowrap;
  height: 40px;
  border: 0;
  width: 236px;
  margin: 0 auto;
  filter: drop-shadow(4px 4px 0px #9EEFD0);
  background-color: $green;
  color: $light;

  &.error {
    background-color: transparent !important;
    filter: none !important;
    margin: auto;
    width: auto;
  }

  .account {
    padding-right: 1.3em;

    span {
      margin: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      transition: all 200ms ease-in-out;
    }

    .part1 {
      max-width: 70px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: .1em;
    }
  }

  img {
    height: 40px;
    width: 40px;
    display: inline-block;
    margin-top: -31px;
    filter: sepia(1) saturate(2) hue-rotate(110deg);
    transition: filter 200ms ease-in-out;
  }

  &.ready,
  &.connecting,
  &.connected {
    cursor: pointer;
    font-family: $font-family-monospace;
    font-weight: $font-weight-bold;

    /**
    &:hover {
      .account {
        span {
          transition: all 200ms ease-in-out;
        }

        .part1 {
          max-width: 350px;
          margin-right: 0em;
        }
      }
      img {
        filter: sepia(0) saturate(1) hue-rotate(0deg);
      }
    }
    **/

    .portfolio-and-account {
      overflow: hidden;
      display: inline-block;
      height: 40px;
      width: 236-40px;
      .your-portfolio {
        letter-spacing: 0.16px;
        padding-top: 2px;
        font-size: 14px;
        line-height: 19px;
      }
      .account {
        font-size: 12px;
        line-height: 17px;
        color: rgba(100,100,100,1);
        font-weight: normal;
      }
    }
    .cp-cancel {
      background-size: contain;
      background-position: right center;
    }
  }
  &.connecting,
  &.ready {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.6px;
    line-height: 40px;
    text-align: center;
  }
  &.connecting {
    padding-right: 45px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' overflow='visible' stroke='black' stroke-width='3' %3E%3Cline x2='50' y2='50' /%3E%3Cline x1='50' y2='50' /%3E%3C/svg%3E");
    background-position: 90% center;
    background-size: 10%;
  }
  outline: 3px solid rgba(0, 255, 99, 0);
}
.clickable {
  cursor: pointer;
  transition: filter 200ms ease-in-out;
  &:hover {
    filter: drop-shadow(0px 0px 4px $green);
  }
}
.glow {
  filter: drop-shadow(0px 0px 6px rgba($green, .5));
}
.nft-container {
  .nft {
    padding: 17px;
    margin: 17px;

    height: 444px;
    width: 261px;

    .nft-add-position-plus {
      //height: 85px;
      //width: 85px;
      overflow: hidden;
      padding-top: 157px;
      text-align: center;
      height: 404px;
      width: 260px;
      //overflow: visible;

      .nft-add-position {
        font-size: 14px;
        border: 0 !important;
        text-underline: none !important;
        margin-top: 105px !important;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 85px;
        width: 85px;
        margin-left: 73px;
        border-radius: 100%;
        background-color: $dark;
        background-repeat: no-repeat;
        background-size: 30%;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' overflow='visible' stroke='%2300FF99' stroke-width='3' %3E%3Cline x2='50' y2='50' /%3E%3Cline x1='50' y2='50' /%3E%3C/svg%3E");
        transform: rotate(45deg);
      }
    }
  }
  &.text-center {
    .nft {
      margin: 17px auto;
    }
  }
  a.nft {
    &:hover {
      filter: drop-shadow(0px 0px 4px $green);
    }
  }

  .nft-sm {
    max-width: 200px;
  }

  .nft-actions {
    button.btn.dropdown-toggle {
      background: $dark;
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 36px;
      border: 2px solid rgba($green, 0);
      overflow: hidden;
      width: 40px;
      height: 40px;
      padding: 0 0 0 2px;
      text-align: center;
      border-radius: 50%;

      &:after {
        display: none;
      }

      &:hover,
      &:active {
        border: 2px solid rgba($green, 1);
        color: $green;
      }
    }
  }
  .cp-footer-invest {
    width: 297px;
    max-height: 45px;
  }
}
.home-wallet-holder {
  // height: 905px;
  // width: 395px;
  // margin: auto;
}
.nft-perspective {
  img {
    max-height: 504px;
    max-width: 288px;
  }
  perspective: 1100px;
}
.nft-perspective2 {
  max-width: 288px;
  margin: auto;
  img {
    //max-width: 288px;
    //max-height: 504px;
  }
  perspective: 2200px;
}
.mint-another-pass {
  color: #32D2B4;
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.16px;
  line-height: 19px;
}
.nft-add-position-plus {
  //height: 455px !important;
  //width: 260px !important;
  //display: block;
  background-color: #211F6F;

  //&:after {
  //  content: "";
  //  display: block;
  //  width: 100%;
  //  height: 100%;
  //  z-index: -1;
  //}
}

.nft-under {
  height: 17px;
  width: 142px;
  color: rgba(255,255,255,0.5);
  font-family: Barlow;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.your-crocpot-pass {
  height: 50px;
  width: 426px;
  color: #32D2B4;
  font-family: Barlow;
  font-size: 30px;
  letter-spacing: 2px;
  line-height: 50px;
}

.card {
  background: #1D1259;
  color: $white;
  border: 0;
}