@import "variables";

body.mint {
  background: $dark;
}

// Hide arrows from number fields
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

// Give room for USDC
#sourceId {
  max-width: 110px;
  padding-right: 0;
}

#invest-logo {
  max-width: 70%;
}

.slogan-vertical {
  min-height: 100%;
}

.walletconnect-modal__header {
  display: none !important;
}

.loading {
  filter: grayscale(1) brightness(0.2);
}

.ellipses {
  white-space: nowrap;
  margin-left: 1.2em;
}

.ellipses:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 2s infinite;
  content: "\2026";
  margin-left: .1em;
}

@keyframes ellipsis {
  from {
    width: 0;
    margin-right: 1.2em;
  }
  to {
    width: 1.2em;
    margin-right: 0;
  }
}

.o-0 {
  transition: opacity 0.5s;
  opacity: 0;
}

.o-50 {
  transition: opacity 0.5s;
  opacity: 50;
}

.o-100 {
  transition: opacity 0.5s;
  opacity: 1;
}

.cp-invest-close-button {
  height: 56px;
  width: 56px;
  position: relative;
  top: -$spacer * 3;
  right: -$spacer * 3;
  background: linear-gradient(192.37deg, #00FFFF 0%, #00FF99 100%);
  text-align: center;

  .cp-cancel {
    height: 25.63px;
    width: 25.63px;
    position: relative;
    top: 15px;
    left: 14px;
  }

  @include media-breakpoint-down(lg) {
    height: 24px;
    width: 24px;
    top: 0;
    right: 0;
    .cp-cancel {
      height: 16px;
      width: 16px;
      top: 5px;
      left: 4px;
    }
  }
}

.cp-token-description {
  margin-top: 28px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.17px;
  line-height: 22px;
  margin-bottom: 8px;
}

.cp-mint-it {
  width: 144px;
}